<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />

    <!-- filter -->
    <ARow :gutter="[8, 8]">
      <ACol :xl="6" :md="8" :sm="12">
        <filter-regional
          style="width:100%"
          v-model:value="region"
          v-can:hide.distributor />
      </ACol>
      <ACol :xl="6" :md="8" :sm="12">
        <filter-province
          style="width:100%"
          v-model:value="provinsi"
          v-model:region="region"
          v-can:hide.distributor/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12">
        <filter-area
          style="width:100%"
          v-model:value="area"
          v-model:area="area"
          v-model:provinsi="provinsi"
          v-model:region="region"
          v-can:hide.distributor/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12">
        <filter-kabupaten-kota
          style="width:100%"
          v-model:value="kabupaten"
          v-model:region="region"
          v-model:area="area"
          v-model:provinsi="provinsi"
          v-can:hide.distributor/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12">
        <FilterDistributorCurah
          style="width:100%"
          v-if="!isDistributor && !isDistributor_khusus"
          v-model:value="distributor"
          v-model:distributor="distributor"
          v-can:hide.distributor/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12">
        <ASelect
          v-model:value="active"
          show-search
          allow-clear
          placeholder="Pilih Status"
          style="width:100%"
          :options="state.statusLists"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12">
        <a-input-search
          v-model:value="q"
          placeholder="Cari ..."
          style="width:100%"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12">
        <a-button
          type="primary"
          title="cari"
          @click="search"
          :loading="isFetching"
        >
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </ACol>
    </ARow>
    
    <div class="row justify-content-end mt-4">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          title="Aktifkan Customer Curah"
          type="primary"
          @click="showConfirm"
          v-has-access="['customers.approve']"
        >
          <i class="fa fa-check" aria-hidden="true"></i>
        </a-button>
        <a-modal
          title="Konfirmasi Penyetujuan Customer Curah"
          v-model:visible="modal5Visible"
          @ok="confirm"
          :confirm-loading="loading"
        >
          <p v-if="selectedRowKeys.length === 0">Checklist customer curah yang akan di setuju</p>
          <p v-else>Anda yakin ingin mengatifkan customer curah?</p>
        </a-modal>

        <a-button
          class="ml-2 mr-2"
          title="Nonaktifkan Customer Curah"
          type="danger"
          @click="showDelete"
          v-has-access="['customers.destroy']"
        >
          <i class="fa fa-ban" aria-hidden="true"></i>
          <a-modal
            title="Informasi"
            v-model:visible="modal2Visible"
            @ok="hapus"
            :confirm-loading="loading"
          >
            <p v-if="status_approval === 0">Checklist customer curah yang akan di nonaktifkan</p>
            <p v-else>Anda yakin ingin nonaktifkan customer curah terpilih?</p>
          </a-modal>
        </a-button>

        <a-button
          title="Tambah Customer Curah"
          type="primary"
          v-has-access="['customers.store']"
          @click="() => {
            modal3Visible = true
            formState.modal_action = 'create'
          }"
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a-button>

        <a-button
          class="ml-2"
          title="download excel"
          type="primary"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
      </div>
    </div>
    <div class="table-responsive text-nowrap mt-2">
      <md-table
        :columns="columns"
        :data-source="data"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #active="{ text }">
          <a-tag v-if="text" color="#108ee9">Aktif</a-tag>
          <a-tag v-else color="grey">Tidak Aktif</a-tag>
        </template>
        <template #status="{ text }">
          <a-tag v-if="text" color="#108ee9">Disetujui oleh ASM</a-tag>
          <a-tag v-else color="grey">Belum disetujui oleh ASM</a-tag>
        </template>
        <template #action="{record}">
          <span>
            <a-tooltip title="Lihat Customer Curah">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                @click="() => {
                  modal3Visible = true
                  formState.modal_action = 'detail'
                  formState.item = record
                }"
                v-has-access="['customers.show']"
              >
                <small>
                  <i class="fe fe-eye" />
                </small>
              </a>
            </a-tooltip>
            <a-tooltip title="Edit Customer Curah">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light ml-2"
                v-has-access="['customers.update']"
                @click="() => {
                  modal3Visible = true
                  formState.modal_action = 'edit'
                  formState.item = record
                }"
              >
                <small>
                  <i class="fe fe-edit" />
                </small>
              </a>
            </a-tooltip>
          </span>
        </template>
      </md-table>
    </div>
  </div>
  <MModal
    v-if="modal3Visible"
    v-model:visible="modal3Visible"
    v-model:item="formState.item"
    :action="formState.modal_action"
    @success="fetchData"/>
</template>

<script>
import { ref, watch, onMounted, reactive, provide, toRefs, computed, h } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import apiClient from '@/services/axios'
import MModal from './modal'
import { Modal, message, Alert } from 'ant-design-vue'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterDistributorCurah from '@/components/filter/FilterDistributorCurah'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterCluster from '@/components/filter/FilterCluster'
import qs from 'qs'
import moment from 'moment'
import { serialize } from 'object-to-formdata'
import useUserInfo from '@/composables/useUserInfo'
import { columns } from './columns'
import _ from 'lodash'

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows)
  },
}
export default {
  name: 'MDataCustomerCurah',
  components: {
    MModal,
    // Modall,
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterDistributorCurah,
    // FilterWilayahSemua,
    FilterKabupatenKota,
    // FilterCluster,
  },
  setup() {
    const route = useRoute()

    const modal1Visible = ref(false)
    const modal2Visible = ref(false)
    const modal3Visible = ref(false)
    const modal4Visible = ref(false)
    const modal5Visible = ref(false)
    const setModal1Visible = visible => {
      modal1Visible.value = visible
    }

    const state = reactive({
      selectedRows: [],
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isDownloading: false,
      statusLists: [
        {
          label: "Tidak Aktif",
          value: 0,
        },
        {
          label: "Aktif",
          value: 1,
        },
      ],
    })

    const loading = ref(false)

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)

    const region = ref([])
    const provinsi = ref([])
    const kabupaten = ref([])
    const distributor = ref([])
    const { isDistributor, vendor_id, isDistributor_khusus } = useUserInfo()
    if (isDistributor.value || isDistributor_khusus.value) {
      distributor.value.push(vendor_id.value)
    }
    const area = ref([])
    const modalRef = ref('')

    const status = ref(null)
    const cluster = ref(null)

    const data = ref([])
    const q = ref('')
    const searchInput = ref(null)
    const active = ref(null)

    const formState = ref({
      item: null,
      modal_action: 'create',
    })

    const errorMessage = ref(null)

    const startRow = ref(1)
    const handleTableChange = pag => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }

    const params = ref({})
    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        region: region.value,
        area: area.value,
        provinsi: provinsi.value,
        kabupaten: kabupaten.value,
        // produk: products.value,
        // brand: brands.value,
        distributor: distributor.value,
        status: status.value,
        q: q.value,
        active: active.value,
        cluster: cluster.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/customer-curah', {
          params: _params,
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const { items, _meta } = response.data
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = error.response.data.message.substring(0, 150)
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    const search = () => {
      fetchData()
    }

    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/customer-curah', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params, ok: 'hmm' }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `master-data-customer-curah_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    const onSelectChange = (selectedRowKeys, selectedRows, dataIndex) => {
      state.selectedRowKeys = selectedRowKeys
      state.selectedRows = selectedRows
    }

    const visible = ref(false)

    const showDelete = visible => {
      if (visible) {
        if (state.selectedRowKeys.length === 0) {
          Modal.warning({
            content: 'Checklist data yang akan di nonaktifkan!',
          })
          return
        }
      }
      modal2Visible.value = visible
    }

    const hapus = () => {
      // approva by single post
      apiClient
        .post('/api/customer-curah/nonactive', {
          ids: state.selectedRowKeys,
          // ids: [103, 104],
        })
        .then(res => {
          state.loading = true
          modal2Visible.value = false
          state.selectedRowKeys = []
          fetchData()
          state.loading = false

          let details = null
          if (res.data && res.data.details) {
            details = res.data.details.map(er => {
              if (!er.errors) return

              return h(
                Alert,
                { type: 'error', message: `Kode Customer Curah (${er.code}) : ${er.errors[0]}` },
                '',
              )
            })
          }

          Modal.info({
            title: 'Reject',
            content: h('span', {}, [`${res.data.message}`, h(`span`, {}, details)]),
            onOk() {
              console.log('ok')
            },
          })
        })
        .catch(response => {
          const { data, status } = response

          if (status === 401) {
            data.message = 'Anda tidak memiliki hak untuk reject data ini!'
          }
          Modal.error({
            title: 'Approval',
            content: response.message,
            onOk() {
              console.log('ok')
            },
          })
        })
    }

    const showConfirm = visible => {
      if (visible) {
        if (state.selectedRowKeys.length === 0) {
          Modal.warning({
            content: 'Checklist data yang akan di konfirmasi!',
          })
          return
        }
      }
      modal5Visible.value = visible
    }

    const confirm = () => {
      // approva by single post
      apiClient
        .post('/api/customer-curah/active', {
          ids: state.selectedRowKeys,
          // ids: [103, 104],
        })
        .then(res => {
          state.loading = true
          modal5Visible.value = false
          state.selectedRowKeys = []
          fetchData()
          state.loading = false

          let details = null
          if (res.data && res.data.details) {
            details = res.data.details.map(er => {
              if (!er.errors) return

              return h(
                Alert,
                { type: 'error', message: `Kode Customer Curah (${er.code}) : ${er.errors[0]}` },
                '',
              )
            })
          }

          Modal.info({
            title: 'Approval',
            content: h('span', {}, [`${res.data.message}`, h(`span`, {}, details)]),
            onOk() {
              console.log('ok')
            },
          })
        })
        .catch(response => {
          const { data, status } = response

          if (status === 401) {
            data.message = 'Anda tidak memiliki hak untuk aktifkan data ini!'
          }
          Modal.error({
            title: 'Approval',
            content: response.message,
            onOk() {
              console.log('ok')
            },
          })
        })
    }

    provide('formState', formState)

    onMounted(() => {
      const { distributor: disti_id } = route.query
      if (disti_id) {
        if (!distributor.value.includes(Number(disti_id))) distributor.value.push(Number(disti_id))
      }
      // active.value = 1
      fetchData()
    })

    return {
      perPage,
      pageCount,
      totalCount,
      meta,
      page,
      rowSelection,
      columns,
      data,
      q,
      searchInput,
      startRow,
      region,
      provinsi,
      kabupaten,
      distributor,
      area,
      state,
      ...toRefs(state),
      fetchData,
      search,
      modal2Visible,
      modal3Visible,
      modal4Visible,
      modal5Visible,
      setModal1Visible,
      fetchXlsx,
      formState,
      onSelectChange,
      visible,
      handleTableChange,
      hapus,
      loading,
      confirm,
      modalRef,
      active,
      errorMessage,
      status,
      cluster,
      showConfirm,
      showDelete,
      isDistributor,
      isDistributor_khusus,
    }
  },
}
</script>
<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}

.ant-table-striped :deep(.table-striped) {
  background-color: #fafafa;
}
</style>
