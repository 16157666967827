<template>
  <AModal
    :visible="visible"
    :title="`${state.title} Customer Curah`"
    width="1200px"
    @cancel="handleModalCancel"
    @ok="handleOkModal">
    <template #footer>
      <AButton
        key="back"
        @click="handleModalCancel">Batal</AButton>
      <AButton
        key="submit"
        type="primary"
        :loading="form.busy"
        @click="handleOkModal"
        :hidden="state.readOnly">{{
          form.id != null ? 'Perbarui' : 'Simpan'
        }}</AButton>
    </template>

    <a-form
      ref="formRef"
      class="myform"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 14 }"
      :scroll-to-first-error="true"
    >
      <a-row class="form-row mb-0">
        <a-col :sm="24">
          <a-form-item
            label="Kode"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            required
            has-feedback
            name="code"
            help="Jika diisi 0 maka dilakukan auto generate number"
          >
            <a-input
              placeholder="Kode"
              width="100%"
              v-model:value="form.code"
              required
              :disabled="action != 'create'"/>
          </a-form-item>
        </a-col>
      </a-row>
      <!-- REFERENSI -->
      <a-form-item
        class="mb-0 lh-1"
        :label-col="{ sm: { span: 5 } }"
        :wrapper-col="{ sm: { span: 24 - 5 } }"
      >
        <a-col :sm="{ span: 24, offset: 5 }">
          <a-row class="myform-vertical" layout="vertical" v-if="form.codes">
            <template v-for="(item, index) in form.codes" :key="index">
              <a-row class="form-row">
                <a-col :sm="5">
                  <a-form-item
                    label="Kode Ship To"
                    label-align="left"
                    :name="['codes', index, 'code']"
                    :rules="{
                      required: true,
                      message: 'code tidak boleh kosong!',
                    }">
                    <a-input
                      size="small"
                      type="number"
                      stylea="width: 150px"
                      v-model:value="item.code"
                      required
                      :disabled="state.readOnly"/>
                  </a-form-item>
                </a-col>
                <a-col :sm="5">
                  <a-form-item
                    label="Nama Ship To"
                    label-align="left">
                    <a-input
                      size="small"
                      stylea="width: 150px"
                      v-model:value="item.name"
                      :disabled="state.readOnly"/>
                  </a-form-item>
                </a-col>
                <a-col
                  v-if="form.distributors.length"
                  :sm="5">
                  <a-form-item
                    label="Kode Sold To"
                    label-align="left">
                    <FilterSoldtoCustomerCurah
                      size="small"
                      stylea="width:150px"
                      show-search
                      v-model:value="item.soldto_curah"
                      v-model:distributor="form.distributors"
                      :disabled="state.readOnly"
                    />
                  </a-form-item>
                </a-col>

                <a-col
                  v-if="!state.readOnly"
                  :sm="4">
                  <a-form-item label=" " label-align="left">
                    <a-button
                      @click="removeRowCodeShip(item)"
                      size="small"
                      type="danger"
                      style="margin-top: 22px"
                      ><i class="fa fa-remove"></i
                    ></a-button>
                  </a-form-item>
                </a-col>
              </a-row>
            </template>
          </a-row>
          <!-- add function -->
          <template v-if="!state.readOnly">
            <a-form-item
              class="mb-0"
              label-align="left"
              :wrapper-col="{ sm: { span: 24 - 4 - 8 } }"
              :colon="false"
            >
              <a-button type="primary" @click="addCodeShip"
                ><i class="fa fa-plus"></i> Tambah Kode Referensi</a-button
              >
            </a-form-item>
          </template>
        </a-col>
      </a-form-item>

      <a-row class="form-row">
        <a-col :md="12" :sm="24">
          <a-form-item
            class="mb-0"
            label="Tanggal Registrasi"
            label-align="left"
            :label-col="{ sm: { span: 8 } }"
            :wrapper-col="{ sm: { span: 24 - 8 } }"
            name="registration_at"
          >
            <a-date-picker
              placeholder="Tanggal Registrasi"
              v-model:value="form.registration_at"
              value-format="YYYY-MM-DD"
              :disabled="state.readOnly"
            />
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-item
            label=""
            label-align="left"
            :label-col="{ sm: { span: 8 } }"
            :wrapper-col="{ sm: { span: 24 - 8 } }"
          >
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :md="12" :sm="24">
          <a-form-item
            class="mb-0"
            label="Nama Customer Curah"
            label-align="left"
            :label-col="{ sm: { span: 8 } }"
            :wrapper-col="{ sm: { span: 24 - 8 } }"
            required
            has-feedback
            name="name"
          >
            <a-input
              placeholder="Nama Customer Curah"
              v-model:value="form.name"
              :disabled="state.readOnly"
            />
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Email"
            class="mb-0"
            label-align="left"
            :label-col="{ sm: { span: 8 } }"
            :wrapper-col="{ sm: { span: 24 - 8 } }"
            name="email"
          >
            <a-input
              placeholder="Contoh : example@mail.com"
              v-model:value="form.email"
              :disabled="state.readOnly"
            >
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Tipe Customer"
            class="mb-0"
            label-align="left"
            :label-col="{ sm: { span: 8 } }"
            :wrapper-col="{ sm: { span: 24 - 8 } }"
            required
            name="type"
          >
            <ASelect
              v-model:value="form.type"
              disabled
              placeholder="Pilih Tipe Customer"
              :options="state.segmentLists"/>
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Grup Pelanggan"
            class="mb-0"
            label-align="left"
            :label-col="{ sm: { span: 8 } }"
            :wrapper-col="{ sm: { span: 24 - 8 } }"
            name="group_customer_id"
            required
          >
            <FilterGroupCustomer
              v-model:value="form.group_customer_id"
              :mode="null"
              :disabled="state.readOnly"/>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Segment"
            class="mb-0"
            label-align="left"
            :label-col="{ sm: { span: 8 } }"
            :wrapper-col="{ sm: { span: 24 - 8 } }"
            required
            name="segment_id"
          >
            <FilterSegments
              v-model:value="form.segment_id"
              :disabled="state.readOnly" />
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Sub Segment"
            label-align="left"
            class="mb-0"
            :label-col="{ sm: { span: 8 } }"
            :wrapper-col="{ sm: { span: 24 - 8 } }"
            required
            name="sub_segment_id"
          >
            <FilterSubSegments
              v-model:value="form.sub_segment_id"
              v-model:segment="form.segment_id"
              :disabled="state.readOnly" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Direct/Indirect"
            class="mb-0"
            label-align="left"
            :label-col="{ sm: { span: 8 } }"
            :wrapper-col="{ sm: { span: 24 - 8 } }"
            name="direct_in">
            <ASelect
              v-model:value="form.direct_in"
              placeholder="Pilih Direct/Indirect"
              allow-clear
              :options="state.directLists"
              :disabled="state.readOnly" />
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-item
            class="mb-0"
            label="Status Akun"
            label-align="left"
            :label-col="{ sm: { span: 8 } }"
            :wrapper-col="{ sm: { span: 24 - 8 } }"
            name="status_akun"
          >
            <ASelect
              v-model:value="form.status_akun"
              placeholder="Pilih Status Akun"
              allow-clear
              :options="state.statusLists"
              :disabled="state.readOnly"/>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <AFormItem
            label="Segmen Infrastruktur"
            label-align="left"
            class="mb-0"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="segmen_infrastruktur"
          >
            <AInput
              placeholder="Segmen Infrastruktur"
              v-model:value="form.segmen_infrastruktur"
              :disabled="state.readOnly"
            />
          </AFormItem>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <AFormItem
            label="Alamat"
            label-align="left"
            class="mb-0"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="address"
          >
            <AInput
              placeholder="Alamat"
              v-model:value="form.address"
              :disabled="state.readOnly"
            />
          </AFormItem>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Kabupaten/Kota"
            class="mb-0"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            required
            name="wilayah_id"
          >
            <filter-wilayah-semua
              v-model:value="form.wilayah_id"
              label="fullname"
              :join-options="
                form.wilayah_id
                  ? [{ id: form.wilayah_id, fullname: form.wilayah }]
                  : []
              "
              :disabled="state.readOnly"
            ></filter-wilayah-semua>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Distributor"
            class="mb-0"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            :max-tag-count="3"
            required
            name="distributors"
            allow-clear
          >
            <FilterDistributorCurah
              show-search
              v-model:value="form.distributors"
              :join-options="form.vendorRaw ? form.vendorRaw : []"
              label="fullname"
              style="width: 100%"
              :disabled="state.readOnly"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Latitude"
            label-align="left"
            class="mb-0"
            :label-col="{ sm: { span: 8 } }"
            :wrapper-col="{ sm: { span: 24 - 8 } }"
            name="lat"
            required
          >
            <a-input
              placeholder="e.g 00.0000"
              v-model:value="form.lat"
              :class="!form.lat ? '' : isValidLatitude != true ? `danger-input` : ``"
              show-count
              :maxlength="18"
              type="text"
              :disabled="state.readOnly"
            />
            <span
              v-if="!form.lat ? '' : isValidLatitude != true"
              :class="
                !form.lat ? '' : isValidLatitude != true ? `text-danger position-danger` : ``
              "
              >*Format anda salah</span
            >
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Longitude"
            class="mb-0"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="lng"
            required
          >
            <a-input
              placeholder="e.g 00.0000"
              v-model:value="form.lng"
              type="text"
              :class="!form.lng ? '' : isValidLongitude != true ? `danger-input` : ``"
              show-count
              :maxlength="18"
              :disabled="state.readOnly"
            />
            <span
              v-if="!form.lng ? '' : isValidLongitude != true"
              :class="
                !form.lng ? '' : isValidLongitude != true ? `text-danger position-danger` : ``
              "
              >*Format anda salah</span
            >
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Pilih Cluster"
            label-align="left"
            class="mb-0"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="cluster_id"
          >
            <filter-cluster
              v-model:value="form.cluster_id"
              style="width: 100%"
              :mode="null"
              :disabled="state.readOnly"
            ></filter-cluster>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Sales Person"
            class="mb-0"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="user"
          >
            <a-form-item
              class="mb-0"
              :label-col="{ sm: { span: 6 } }"
              :wrapper-col="{ sm: { span: 24 - 6 } }"
            >
              <a-col :sm="{ span: 24, offset: 6 }" :md="{ span: 12, offset: 0 }">
                <a-row class="myform-vertical" layout="vertical">
                  <template v-for="(item, index) in form.users" :key="index">
                    <a-input
                      size="small"
                      type="hidden"
                      v-model:value="item.vendor_id"
                      :disabled="form.readOnly || item.id !== null"
                    />
                    <a-row class="form-row">
                      <a-col :sm="12" :md="12">
                        <a-form-item
                          label="Role"
                          class="mb-0"
                          label-align="left"
                          :wrapper-col="{ span: 24 }"
                          :name="['users', index, 'role_id']"
                        >
                          <filter-role
                            size="small"
                            :mode="null"
                            type="text"
                            class="mb-0 lh-1"
                            style="width: 95%"
                            v-model:value="item.role_id"
                            placeholder="Pilih Role"
                            disabled
                          ></filter-role>
                        </a-form-item>
                      </a-col>
                      <a-col :sm="12" :md="12">
                        <a-form-item
                          label="User"
                          class="mb-0 "
                          label-align="left"
                          :wrapper-col="{ span: 26 }"
                          :name="['users', index, 'user_id']"
                          :help="
                            Array.isArray(item.errors)
                              ? item.errors.find(i => i.field === 'user_id')
                                ? item.errors.find(i => i.field === 'user_id').message
                                : undefined
                              : undefined
                          "
                          :validate-status="
                            Array.isArray(item.errors)
                              ? item.errors.find(i => i.field === 'user_id') !== null
                                ? 'error'
                                : undefined
                              : undefined
                          "
                        >
                          <FilterSalesKAM
                            size="small"
                            :mode="null"
                            type="text"
                            class="mb-0 lh-1"
                            v-model:value="item.user_id"
                            :disabled="state.readOnly"
                          />
                        </a-form-item>
                      </a-col>
                    </a-row>
                  </template>
                </a-row>
              </a-col>
            </a-form-item>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <div v-if="form.errors.any()">
      <ASpace>
        <AAlert
          v-for="(item, index) in form.errors.all()"
          type="error"
          :key="index"
          :message="item.message"
          banner
          closable />
      </ASpace>
    </div>
  </AModal>
</template>
<script>
import {
  defineComponent,
  ref,
  reactive,
  computed,
  onMounted,
  watch,
} from 'vue'
import { message } from 'ant-design-vue'
import rules from './rules'
import FilterWilayahSemua from '@/components/filter/FilterWilayahSemua'
import FilterRole from '@/components/filter/FilterRole'
import FilterSalesKAM from '@/components/filter/FilterSalesKAM'
import FilterCluster from '@/components/filter/FilterCluster'
import FilterGroupCustomer from '@/components/filter/FilterGroupCustomer'
import FilterDistributorCurah from '@/components/filter/FilterDistributorCurah'
import FilterSegments from '@/components/filter/FilterSegments'
import FilterSubSegments from '@/components/filter/FilterSubSegments'
import FilterSoldtoCustomerCurah from '@/components/filter/FilterSoldtoCustomerCurah'
import Form from 'vform'
import moment from 'moment'
import uniq from 'lodash/uniq'
import apiClient from '@/services/axios'

export default defineComponent({
  components: {
    FilterWilayahSemua,
    FilterRole,
    FilterSalesKAM,
    FilterCluster,
    FilterGroupCustomer,
    FilterDistributorCurah,
    FilterSegments,
    FilterSubSegments,
    FilterSoldtoCustomerCurah,
  },
  props: {
    visible: [Boolean],
    action: {
      type: String,
      default: 'create',
    },
    item: {
      type: Object,
      default: () => ({
        id: null,
      }),
    },
  },
  emits: ['update:visible'],
  setup(props, { emit }) {
    const isAddCode = ref(true)
    const formRef = ref('')
    const state = reactive({
      title: 'Tambah',
      readOnly: false,
      directLists: [
        {
          label: 'Direct',
          value: 1,
        },
        {
          label: 'Indirect',
          value: 2,
        },
      ],
      statusLists: [
        {
          label: 'PARETO',
          value: 1,
        },
        {
          label: 'NON PARETO',
          value: 2,
        },
      ],
      segmentLists: [
        {
          label: 'Customer Curah',
          value: 1111,
        },
      ],
    })

    const form = reactive(new Form({
      id: null,
      code: '',
      codes: [],
      registration_at: null,
      name: '',
      email: '',
      type: 1111,
      group_customer_id: null,
      segment_id: null,
      sub_segment_id: null,
      direct_in: null,
      status_akun: null,
      segmen_infrastruktur: '',
      address: '',
      wilayah_id: null,
      distributors: [],
      vendors: [],
      lat: '',
      lng: '',
      cluster_id: null,
      users: [
        {
          role_id: 1260026,
          user_id: null,
        },
      ],
    }))

    /// vendor code
    const addCodeShip = () => {
      if (form.codes.filter(v => v.code === null).length > 0) {
        message.warning('Pastikan kode referensi terisi!')
        return
      }

      isAddCode.value = false
      form.codes.push({
        code: null,
        name: '',
        soldto_curah: '',
      })
    }

    const removeRowCodeShip = item => {
      const index = form.codes.indexOf(item)
      form.codes.splice(index, 1)
    }

    const inputValueLat = ref('')
    const inputValueLong = ref('')

    const latRegex = /^-?(?:90(?:\.0+)?|[0-8]?\d(?:\.\d+)?)$/

    const longRegex = /^-?(?:180(?:\.0+)?|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:\.\d+)?)$/

    const isValidLatitude = computed(() => {
      return latRegex.test(form.lat)
    })
    
    const isValidLongitude = computed(() => {
      return longRegex.test(form.lng)
    })

    const handleModalCancel = () => {
      emit('update:visible', false)
      emit('update:item', null)
    };

    const handleOkModal = async () => {

      await formRef.value.validate()

      uniq(form.distributors).forEach(item => {
        form.vendors.push({
          vendor_id: item,
        })
      })

      if (form.id != null) {
        form.put(`/api/customer-curah/${form.id}`)
          .then(({ data }) => {
            if (data === undefined) {
              errorMessage.value = `Kode error 500, No response from server`
              return
            }

            message.success('Berhasil diperbarui')

            form.reset()
            handleModalCancel()

            emit('success', data)
          })
      } else {
        form.post('/api/customer-curah')
          .then(({ data }) => {
            if (data === undefined) {
              errorMessage.value = `Kode error 500, No response from server`
              return
            }

            message.success('Berhasil ditambahkan')

            form.reset()
            handleModalCancel()

            emit('success', data)
          })
      }
    };

    const updateForm = (item) => {
      form.id = item.id
      form.code = item.code
      form.registration_at = item.registration_at ? moment(item.registration_at).format('YYYY-MM-DD') : null
      form.name = item.name
      form.email = item.email
      form.type = item.type
      form.group_customer_id = item.group_customer_id
      form.segment_id = item.segment_id
      form.sub_segment_id = item.sub_segment_id
      form.direct_in = parseInt(item.direct_in) || ''
      form.status_akun = parseInt(item.status_akun) || ''
      form.segmen_infrastruktur = item.segmen_infrastruktur
      form.address = item.address
      form.wilayah_id = item.wilayah_id
      form.distributors = item.vendors.map(item => item.id)
      form.lat = item.lat
      form.lng = item.lng
      form.cluster_id = item.cluster_id

      item.codes.forEach((item, index) => {
        form.codes[index] = {
          code: item.code,
          name: item.name,
          soldto_curah: item.soldto_curah,
        }
      })
      item.users.forEach((item, index) => {
        if (item.user_id) {
          form.users[index] = {
            role_id: item.role_id,
            user_id: item.user_id,
          }
        }
      })
    }

    const fetchDetail = id => {
      apiClient
        .get(`/api/customer-curah/${id}`)
        .then(({ data }) => {
          updateForm(data)
        })
        .catch(error => console.error({ showFailed: error }))
        .catch(error => console.error({ showCodesFailed: error }))
    }

    // handle vue
    onMounted(() => {
      if (props.action == 'edit') {
        fetchDetail(props.item.id)
        state.title = 'Edit'
      } else if (props.action == 'detail') {
        fetchDetail(props.item.id)
        state.readOnly = true
        state.title = 'Lihat'
      }
    })

    return {
      longRegex,
      latRegex,
      inputValueLat,
      inputValueLong,
      form,
      addCodeShip,
      removeRowCodeShip,
      isAddCode,
      formRef,
      rules,
      isValidLatitude,
      isValidLongitude,
      state,
      handleModalCancel,
      handleOkModal,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';

.danger-input {
  border: 1px solid red;
}

.position-danger {
  position: relative;
  top: -10px;
}
</style>
